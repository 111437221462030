import * as React from 'react';
import { Link } from 'react-router-dom';

export function Overview() {

  return (
    <div id="overview">
      <div className="overviewInfoContainer">
          <h4 className="subTitle">Overview</h4>
          <p>Gone are the days of archaic hand signals, shoulder tapping, yelling, jumping, utter frustration, and delayed responses to a coach's time-out request in organized sports. </p><p>T.O.S.S. is the coach's immediate voice to the referee.</p>
          <Link to="/About">
            <button className='overview-button'>Learn More</button>
          </Link>
      </div>
      <div className="overviewImageContainer"/>
    </div>
  )

}
