import * as React from 'react';
import animatedIntroMp4 from "../videos/TOSSAnimatedCompressed.mp4";
import animatedIntroWebm from "../videos/TOSSAnimatedCompressed.webm";

export function IntroVideo() {
    const videoRef = React.useRef(null);
    const observerRef = React.useRef(null);

    React.useEffect(() => {
        const videoElement = videoRef.current;

        const handleIntersect = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    videoElement.play(); // Play the video when in view
                } else {
                    videoElement.pause(); // Pause when out of view
                }
            });
        };

        observerRef.current = new IntersectionObserver(handleIntersect, {
            threshold: 0.5,
        });

        if (videoElement) {
            observerRef.current.observe(videoElement);
        }

        return () => {
            if (observerRef.current && videoElement) {
                observerRef.current.unobserve(videoElement);
            }
        };
    }, []);

    return (
        <div id='introVideo'>
            <div className='introVideoContainer'>
                <video
                    ref={videoRef}
                    width="70%"
                    controls
                    style={{ background: "black" }}
                    muted
                >
                    <source src={animatedIntroMp4} type='video/mp4' />
                    <source src={animatedIntroWebm} type='video/webm' />
                    Your browser does not support the video tag.
                </video>
            </div>
        </div>
    );
}
