import * as React from 'react';

export function CarouselItem ({ children, width, height }){

  return(
    <div className="carouselItem" style ={{ width: width, height: height }}>
      { children }
    </div>
  )
}
