import * as React from 'react';
import { Link } from "react-router-dom";

export function HorizontalNav() {
  const currentPath = window.location.pathname;

  const homeScrollToTop = () => {
    if (currentPath === '/' || currentPath === '/Home') {
      window.scrollTo(0, 0);
    }
  };

  return (
    <div className="horizontalNav">
      <Link to="/Home" onClick={homeScrollToTop}>
        <div className="imageContainer" />
      </Link>
      <div className="horizontalLinks">
        {/* Conditionally render the links based on the current path */}
        {(currentPath !== '/' && currentPath !== '/Home') && <Link to="/">Home</Link>}
        <Link to="/About">About</Link>
        <Link to="/Questions">Questions</Link>
        <Link to="/Contact">Contact</Link>
      </div>
    </div>
  );
}
