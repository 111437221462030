import { HorizontalNav } from "../components/HorizontalNav";
import { Journey } from "../components/Journey";
import { HamburgerNav } from "../components/HamburgerNav"

export default function About() {
  return (
    <>
      <HorizontalNav />
      <HamburgerNav />
      <div>
        <Journey/>
      </div>
    </>
  );
}
