import { HorizontalNav } from "../components/HorizontalNav";
import { HamburgerNav } from "../components/HamburgerNav"

export default function Contact() {
  return (
    <>
      <HorizontalNav />
      <HamburgerNav />
      <div className="contact-container">
        <div className="contact-image-container">
          <div className="contact-title">
            <h1>Let's Start a Conversation</h1>
          </div>
        </div>
        <h1 className="contact-subtitle">Contact Us</h1>
        <div className="contact-info">
          <p>For further information about the TOSS system, please reach out to us at:</p>
          <p>Maria Carthon</p>
          <p><strong>Email:</strong> <a href="mailto: mariacarthon@tosssystem.com">mariacarthon@tosssystem.com</a></p>
          <p><strong>Phone:</strong> <a href="tel:+17706346596">(770) 634-6596</a></p>
        </div>
      </div>
    </>
  );
}
